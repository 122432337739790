
// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
import { apiCall } from "../../../components/src/utils";
import { toast } from "react-toastify";
import { TFunction } from "i18next";


interface RecommededAttributes {
  id: number;
  certificate_number: string | null;
  account_id: number;
  certification_id: number;
  organization_id: number;
  organization_name: string;
  certificate_name: string;
  document: string | null;
}

interface Recommeded {
  id: string;
  type: string;
  attributes: RecommededAttributes;
}

interface RecommededData {
  data: Recommeded;
}

export interface TopFitCoach {
  id: string;
  type: string;
  attributes: {
    id: number;
    first_name: string | null;
    full_name: string;
    last_name: string | null;
    user_bio: string | null;
    experience: number;
    profile_pic: string;
    qualifications: RecommededData;
  };
}

export interface TopFitResponse {
  data: TopFitCoach[];
}

interface SurveyDataItem {
  questions: string;
  answers: string[];
}

interface IAssessmentList {
  id: string;
  type: string;
  attributes: {
    id: number;
    assessment_id: number;
    assessment_name: string;
    client_id: number;
    client_name: string;
    coach_id: number;
    start_date: string;
    due_date: string;
    status: string;
    result_status: string;
    result: null | string;
    description: string;
    file_size: string;
    coach_profile_pic: string;
    coach_name: string;
    resource_url: string | null
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: { [key: string]: string },
  t: TFunction;
  i18n: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

interface S {
  // Customizable Area Start
  profileResponse: {
    id: number,
    full_name: string,
    profile_pic: string
  },
  surveryData: {
    id: number,
    name: string,
    created_at: string
  }[]
  surveyScreen: boolean,
  AssessmentScreen: boolean,
  surveyMainscreen: boolean
  surveyAssessValue: string;
  isHovered: number | null,
  isHoveredsurvey: number | null,
  surveyResultsScreen: boolean,
  recommendedCoach: boolean,
  resultsScreen: boolean,
  visibleCount: number,
  surveyResultsdata: SurveyDataItem[]
  recommendedCoachData: Array<TopFitCoach>
  logoutModal: boolean;
  sidebarExpanded: boolean;
  showModal: boolean;
  profileDetails: { full_name: string; profile_pic: string; email: ""; }
  isLoading: boolean;
  token: string;
  assessmentList: IAssessmentList[];
  isViewAssessmentDetailsOpen: boolean;
  assessmentId: number;
  singleAssessment: IAssessmentList;
  breadCrumb1: "" | "Assessments";
  breadCrumb2: "" | "Assessment Details";
  surveyId: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class SurveyAssessmentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apigetRecommededcoachCallId: string = ""
  apigetSurveyCallid: string = ""
  apigetSurveyresultsCallid: string = ""
  getProfileDetails: string = "";
  getAssessmentListApiCallId: string = "";
  updateAssessmentApiCallId: string = "";
  getSingleAssessmentApiCallId: string = "";
  uploadAssessmentResultApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.SurveyId)
    ];

    this.state = {
      profileResponse: {
        id: 0,
        full_name: "",
        profile_pic: ""
      },
      surveyScreen: true,
      isHovered: null,
      isHoveredsurvey: null,
      AssessmentScreen: false,
      surveyMainscreen: true,
      surveyResultsScreen: false,
      recommendedCoach: false,
      resultsScreen: false,
      visibleCount: 3,
      recommendedCoachData: [],
      surveryData: [],
      surveyResultsdata: [],
      logoutModal: false,
      sidebarExpanded: false,
      showModal: false,
      profileDetails: { full_name: "", profile_pic: "", email: "" },
      token: "",
      isLoading: false,
      breadCrumb1: "",
      surveyAssessValue: "",
      breadCrumb2: "",
      assessmentList: [],
      isViewAssessmentDetailsOpen: false,
      assessmentId: 0,
      singleAssessment: {
        id: "",
        type: "",
        attributes: {
          assessment_id: 0,
          assessment_name: "",
          file_size: "",
          client_id: 0,
          client_name: "",
          coach_id: 0,
          description: "",
          due_date: "",
          result: "",
          id: 0,
          result_status: "",
          start_date: "",
          status: "",
          coach_name: "",
          coach_profile_pic: "",
          resource_url: ""
        },
      },
      surveyId: 0,
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.apigetRecommededcoachCallId:
            this.responseCoach(responseJson);
            break;
          case this.apigetSurveyCallid:
            this.responseSurvey(responseJson);
            break;
          case this.apigetSurveyresultsCallid:
            this.responseSurveyresults(responseJson)
            break;
          case this.getProfileDetails:
            this.setState({ profileDetails: responseJson.data.attributes })
            break;
          case this.getAssessmentListApiCallId:
            this.handleAssessmentListResponse(responseJson);
            break;
          case this.updateAssessmentApiCallId:
            this.handleUpdateAssessmentsResponse(responseJson);
            break;
          case this.getSingleAssessmentApiCallId:
            this.handleSingleAssessmentResponse(responseJson);
            break;
          case this.uploadAssessmentResultApiCallId:
            this.handleAssessmentResultUploadResponse(responseJson);
            break;
          default:
            break;
        }
      }
      this.setState({
        isLoading: false
      });
    }
    else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let itemDetail = message.getData(getName(MessageEnum.SurveyId));
      this.setState({ surveyAssessValue: itemDetail })
    }
  }

  async componentDidMount() {
    const token = localStorage.getItem("token");
    if (token) {
      this.setState({
        token
      })
    };
    this.handleSurveyapi(token);
    this.handleProfileDetails()
  }

  responseCoach = (responseJson: TopFitResponse
  ) => {

    if (responseJson.data) {
      this.setState({
        recommendedCoachData: responseJson.data
      })
    }
  }

  responseSurvey = (responseJson: { messge: { id: number; name: string; created_at: string; }[]; }) => {
    if (responseJson) {
      this.setState({
        surveryData: responseJson.messge
      })
    }
  }

  responseSurveyresults = (responseJson: { message: SurveyDataItem[]; }) => {

    if (responseJson) {
      this.setState({
        surveyResultsdata: responseJson.message,
      });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevState.surveyAssessValue !== this.state.surveyAssessValue) {
      if (this.state.surveyAssessValue) {
        this.handleAssessment();
      }
    }
  };

  handleAssessment = () => {

    this.setState({
      AssessmentScreen: true,
      surveyScreen: false,
      resultsScreen: false,
      recommendedCoach: false,
      isViewAssessmentDetailsOpen: false,
      breadCrumb1: "Assessments",
      breadCrumb2: "",
    }, () => {
      this.getAssessmentList();
    })
  }
  handleSurvey = () => {
    this.setState({
      surveyMainscreen: true,
      surveyScreen: true,
      resultsScreen: false,
      recommendedCoach: false,
      surveyResultsScreen: false,
      AssessmentScreen: false,
      breadCrumb1: "",
      breadCrumb2: "",
    }, () => {
      this.handleSurveyapi(this.state.token);
    })
  }
  handleSurveyresults = (surveyId: number) => {
    this.setState({
      surveyMainscreen: false,
      surveyResultsScreen: true,
      resultsScreen: true,
      surveyId
    }, () => {
      this.handleSurveyreultsApi(this.state.token);
    })
  }
  handleResults = () => {
    this.setState({
      resultsScreen: true,
      recommendedCoach: false
    }, () => {
      this.handleSurveyreultsApi(this.state.token)
    })
  }
  handleRecommendedCoach = () => {
    this.setState({
      resultsScreen: false,
      recommendedCoach: true,
      recommendedCoachData: []

    }, () => {
      this.recommededCoach(this.state.token);
    })
  }
  handlesurveyHover = (index: number) => {
    this.setState({
      isHoveredsurvey: index
    })
  };

  handleSurveyLeave = () => {
    this.setState({
      isHoveredsurvey: null
    })
  };
  showMore = () => {
    this.setState(prevState => ({
      visibleCount: prevState.visibleCount + 3
    }));
  };

  navigationCoachprofile = async (coachID: number) => {
    await setStorageData("coachID", coachID);
    await setStorageData("name", this.state.profileDetails.full_name);
    await setStorageData("email", this.state.profileDetails.email);
    await setStorageData("previousRoute", "SurveyAssessment");
    const msg: Message = new Message(getName(MessageEnum.CoachProfileMessageData));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.CoachID), coachID);
    this.send(msg);
  }

  recommededCoach = async (token: string | null) => {
    this.setState({
      isLoading: true,
    });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    this.apigetRecommededcoachCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/coach_profiles?exec_survey_id=${this.state.surveyId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSurveyapi = async (token: string | null) => {
    this.setState({
      isLoading: true,
    });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    this.apigetSurveyCallid = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.surveyAPiurl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSurveyreultsApi = async (token: string | null) => {
    this.setState({
      isLoading: true,
    });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    this.apigetSurveyresultsCallid = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_surveys/surveys/${this.state.surveyId}/get_specific_survey`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleProfileDetails =
    () => {
      this.setState({
        isLoading: true,
      });
      let token =
        localStorage.getItem("token");
      const headers = {
        "token": token,
      };
      const requestMessage =
        new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
      this.getProfileDetails =
        requestMessage.messageId;
      requestMessage.addData(
        getName(
          MessageEnum.RestAPIResponceEndPointMessage),
        "/account_block/accounts/get_profile"
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(
          headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );

      runEngine.sendMessage(
        requestMessage.id, requestMessage
      );

      return true;
    };

  handleSidebarToggle =
    (expanded: boolean) => {
      this.setState({ sidebarExpanded: expanded });
    };

  handleLogoutModalOpen = () => {
    this.setState(
      { showModal: true });
  }

  handleLogoutClose = () => {
    this.setState({ showModal: false }
    );
  }

  handleLogoutUser = async () => {
    this.setState({ showModal: false });
    await removeStorageData(
      'token');
    await removeStorageData(
      "userType"); const landingPage: Message = new Message(getName(MessageEnum.NavigationMessage));
    landingPage.addData(getName(
      MessageEnum.NavigationTargetMessage), "LandingPage");
    landingPage.addData(getName(
      MessageEnum.NavigationPropsMessage), this.props); this.send(
        landingPage);
  }

  handleSettingNavigation =
    () => {
      const message: Message = new Message(getName(
        MessageEnum.NavigationMessage));
      message.addData(getName(
        MessageEnum.NavigationTargetMessage), "ClientSetting"); message.addData(getName(
          MessageEnum.NavigationPropsMessage), this.props); this.send(
            message);
    };
  handleTimeZone = (createdAt: string) => {
    let createdAtDate = new Date(createdAt);
    let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let localDateString = new Date(createdAtDate.toLocaleString('en-US', { timeZone: timeZone }));
    let localDate = new Date(localDateString);
    let localYear = localDate.getFullYear();
    let localMonth = localDate.toLocaleString('en-US', { month: 'short' });
    let localDay = localDate.getDate();
    let localHours = localDate.getHours();
    let localMinutes = localDate.getMinutes();
    let period = localHours >= 12 ? 'pm' : 'am';
    localHours = localHours % 12;
    localHours = localHours ? localHours : 12;
    let localDays = localDay < 10 ? '0' + localDay : localDay;
    let localMinute = localMinutes < 10 ? '0' + localMinutes : localMinutes;
    let localTimeString = `${localDays}-${localMonth}-${localYear} ${localHours}:${localMinute} ${period}`;
    return localTimeString;
  }
  handleWallletNavigation = () => {
    const message: Message =
      new Message(
        getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(
        MessageEnum.NavigationTargetMessage), "Cfwallet44"
    );
    message.addData(
      getName(MessageEnum.NavigationPropsMessage
      ), this.props
    );
    this.send(message);
  };
  handleHelpCenterNavigation = () => {
    const message: Message =
      new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "HelpCenter"
    );
    message.addData(
      getName(MessageEnum.NavigationPropsMessage
      ), this.props
    );
    this.send(
      message);
  };
  handleViewProfile = () => {
    const message:
      Message = new Message(
        getName(
          MessageEnum.NavigationMessage)
      );
    message.addData(
      getName(
        MessageEnum.NavigationTargetMessage),
      "Profile"
    );
    message.addData(
      getName(MessageEnum.NavigationPropsMessage
      ), this.props
    );
    this.send(
      message);
  };

  dateFormat = (date: string) => {
    return moment.utc(date).local().format("DD MMM");
  };

  dateFormatYear = (date: string) => {
    return moment.utc(date).local().format("DD/MM/yyyy");
  };

  downloadFileFromURL = async (filePath: string, fileName: string) => {
    this.setState({
      isLoading: true,
    });
    const link = document.createElement("a");
    link.href = filePath;
    link.setAttribute("download", fileName);
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) {
      link.parentNode.removeChild(link);
    }
    this.setState({
      isLoading: false,
    });
  };

  getAssessmentList = async () => {
    this.setState({
      isLoading: true,
    });
    this.getAssessmentListApiCallId = await apiCall({
      endPoint: configJSON.getAssessmentListEndPoint,
      token: this.state.token,
      contentType: configJSON.getAssessmentListContentType,
      method: configJSON.getAssessmentListMethod,
    });
  };

  handleAssessmentListResponse = (
    webResponseJson: {
      client_assessments: { data: IAssessmentList[] };
    }
  ) => {
    if (
      webResponseJson.client_assessments.data
    ) {
      this.setState({
        assessmentList: webResponseJson.client_assessments.data,
      });
    }
  };

  updateAssessmentStatus = async (assessmentId: number) => {
    this.setState({
      isLoading: true,
    });
    this.updateAssessmentApiCallId = await apiCall({
      endPoint: `${configJSON.updateAssessmentEndPoint}/${assessmentId}?status=in_progress`,
      token: this.state.token,
      contentType: configJSON.updateAssessmentContentType,
      method: configJSON.updateAssessmentMethod,
    });
  };

  handleUpdateAssessmentsResponse = (
    webResponseJson: {
      client_assessments: { data: IAssessmentList };
    }
  ) => {
    if (webResponseJson.client_assessments.data
    ) {
      toast.success("Assignment status updated successfully.");
      if (this.state.isViewAssessmentDetailsOpen) {
        this.getSingleAssessment();
      } else {
        this.getAssessmentList();
      }
    }
  };

  openViewAssessmentModal = (assessmentId: number) => {
    this.setState({ isViewAssessmentDetailsOpen: true, assessmentId, breadCrumb2: "Assessment Details", }, () => {
      this.getSingleAssessment();
    });
  };

  closeViewAssessmentModal = () => {
    this.setState({ isViewAssessmentDetailsOpen: false, assessmentId: 0, breadCrumb2: "", }, () => {
      this.getAssessmentList();
    });
  };

  getSingleAssessment = async () => {
    this.setState({
      isLoading: true,
    });
    this.getSingleAssessmentApiCallId = await apiCall({
      endPoint: `${configJSON.getSingleAssessmentEndPoint}/${this.state.assessmentId}`,
      token: this.state.token,
      contentType: configJSON.getSingleAssessmentContentType,
      method: configJSON.getSingleAssessmentMethod,
    });
  };

  handleSingleAssessmentResponse = (webResponseJson: {
    client_assessments: { data: IAssessmentList };
  }) => {
    if (webResponseJson.client_assessments.data) {
      this.setState({
        singleAssessment: webResponseJson.client_assessments.data,
      });
    }
  };

  uploadResultAssessment = async (result: null | File) => {
    this.setState({
      isLoading: true,
    });
    const formData = new FormData();
    if (result)
      formData.append("result", result);
    this.uploadAssessmentResultApiCallId = await apiCall({
      endPoint: `${configJSON.uploadResultEndPoint}?id=${this.state.assessmentId}`,
      token: this.state.token,
      method: configJSON.uploadResultMethod,
      body: formData
    });
  };

  handleAssessmentResultUploadResponse = (webResponseJson: { message: string }) => {
    if (webResponseJson.message) {
      toast.success(webResponseJson.message);
      this.getSingleAssessment();
    }
  }

  handleBreadCrumbClick = () => {
    this.setState({
      breadCrumb2: "",
      isViewAssessmentDetailsOpen: false,
    }, () => {
      this.getAssessmentList();
    })
  }

  redirectToSurveyPage = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "TakeNewSurvey"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  // Customizable Area End
}
