import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import {
  Box,
  Typography,
  styled,
  Container,
  Dialog,
  IconButton,
  DialogContent,
} from "@material-ui/core";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Close } from "@material-ui/icons";
import { withTranslation } from "react-i18next";
import { i18n, TFunction } from "i18next";
import { addClassIfArabic } from "../utils";

const arc = require("./arc.png");
const logo2 = require("./logo2.png");
const Twitter = require("./Twitter.svg");
const Instagram = require("./Instagram.svg");
const Facebook = require("./Facebook.svg");
const LinkedIn = require("./Linkedin.svg");
const mada = require("./mada.svg");
const Vector = require("./Vector.svg");
const Layer = require("./Layer.svg");
const apple = require("./apple.svg");
const Sadad = require("./Sadad.svg");
const tabby = require("./tabby.svg");
const frame = require("./Frame.svg");
const upImg = require("./up.svg");
const qrcode = require("./qrcode.png");
const pdfLink = require("./VAT.pdf");

interface IProps {
  navigation: any;
  topToScroll: () => void;
  navigateToContactUsPage?: () => void;
  t: TFunction;
  i18n:i18n;
  socialMediaLinks: any;
}

interface IState {
  openVatModal: boolean;
}

interface ISS {}

class Footer extends BlockComponent<IProps, IState, ISS> {
  constructor(props: IProps) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
      openVatModal: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  socialLink = [
    {
      path: this.props.socialMediaLinks.twitter,
      icon: Twitter,
      label: "twitter",
    },
    {
      path: this.props.socialMediaLinks.facebook,
      icon: Facebook,
      label: "facebook",
    },
    {
      path: this.props.socialMediaLinks.instagram,
      icon: Instagram,
      label: "instagram",
    },
    {
      path: this.props.socialMediaLinks.linkedin,
      icon: LinkedIn,
      label: "linkedin",
    },
  ];

  individualHandleBtn = () => {
    this.props.navigation.navigate("ExecutiveAccountCreation", { stepper: 0 });
  };

  handleJoinAsCoach = () => {
    this.props.navigation.navigate("ReadyToInspire", { activeStep: 0 });
  };

  handleFaqNavigation = () => {
    this.props.navigation.navigate("FAQScreen");
  };

  handleTermsNavigation = () => {
    this.props.navigation.navigate("TermsConditions");
  };

  handlePrivacyNavigation = () => {
    this.props.navigation.navigate("PrivacyPolicy");
  };

  handleAboutUsNavigation = () => {
    this.props.navigation.navigate("AboutUs");
  };

  otherPageToContactUs = () => {
    const msg: Message = new Message(
      getName(MessageEnum.LandingPageMessageData)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.page), "4");
    this.send(msg);
  };

  handleLinkClick = (url: string) => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) {
      link.parentNode.removeChild(link);
    }
  };

  handleSocialLinkClick = (socialMedia: string) => {
    const key = socialMedia.toLowerCase();
    const url = this.props.socialMediaLinks[key];
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) {
      link.parentNode.removeChild(link);
    }
  };

  navigateContactUsPage = () => {
    const { navigateToContactUsPage } = this.props;
    navigateToContactUsPage
      ? navigateToContactUsPage()
      : this.otherPageToContactUs();
  };

  redirectToComingSoonPages = () => {
    this.props.navigation.navigate("ComingSoonPage");
  };

  openPDFModal = () => {
    this.setState({
      openVatModal: true,
    });
  };

  closePDFModal = () => {
    this.setState({
      openVatModal: false,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <Dialog
          open={this.state.openVatModal}
          maxWidth="md"
          onClose={this.closePDFModal}
          fullWidth
          scroll="body"
          PaperProps={{
            style: {
              position: "relative",
              overflow: "visible",
              borderRadius: "40px",
              boxShadow: "none",
            },
          }}
        >
          <IconButton
            aria-label="close"
            onClick={this.closePDFModal}
            data-test-id="closeImagePopup"
            style={{
              position: "absolute",
              top: "-12px",
              right: "-12px",
              backgroundColor: "#fff",
              borderRadius: "50%",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              padding: "10px",
            }}
          >
            <Close style={{ color: "#0F172A" }} />
          </IconButton>
          <DialogContent style={{ margin: "30px", padding: 0 }}>
            <iframe
              style={{
                width: "100%",
                height: "80vh",
                border: "none",
                borderRadius: "20px",
              }}
              src={`${pdfLink}#toolbar=0&navpanes=0&scrollbar=0`}
            />
          </DialogContent>
        </Dialog>
        <FooterStyles>
          <img src={arc} className="right-half-circle" alt="Arc" />
          <Container className="footer-container">
            <Box className="footer-text-image-container">
              <img src={logo2} className="main-coachy-image" alt="Logo 2" />
              <Box className="footer-link-container">
                <Box className="quick-link-text-container">
                  <Box className="quick-link-text-container-1">
                    <Typography
                      className="footer-main-left-text footer-main-text"
                    >
                      {t("footer.quick_links")}
                    </Typography>
                    <Box className="quick-link-text-container-1">
                      <Typography
                        className="quick-links"
                        onClick={this.redirectToComingSoonPages}
                      >
                        {t("common.for_business")}
                      </Typography>
                      <Typography
                        className="quick-links"
                        data-test-id="handleGotoIndividualButton"
                        onClick={this.individualHandleBtn}
                      >
                        {t("common.for_individuals")}
                      </Typography>
                      <Typography
                        className="quick-links"
                        onClick={this.redirectToComingSoonPages}
                      >
                        {t("common.on_demand_coaching")}
                      </Typography>
                      <Typography
                        className="quick-links"
                        onClick={this.redirectToComingSoonPages}
                      >
                        {t("footer.coaching_calculator")}
                      </Typography>
                      <Typography
                        className="quick-links"
                        onClick={this.handleJoinAsCoach}
                      >
                        {t("footer.join_as_coach")}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="quick-link-text-container-1 for-responsive-tablet">
                    <Typography
                      className="quick-links"
                      data-test-id="faq-click"
                      onClick={this.handleFaqNavigation}
                    >
                      {t("footer.faqs")}
                    </Typography>
                    <Typography
                      className="quick-links"
                      onClick={this.redirectToComingSoonPages}
                    >
                      {t("footer.articles_and_news")}
                    </Typography>
                    <Typography
                      className="quick-links"
                      data-test-id="terms-click"
                      onClick={this.handleTermsNavigation}
                    >
                      {t("footer.terms_and_conditions")}
                    </Typography>
                    <Typography
                      className="quick-links"
                      data-test-id="privacy-click"
                      onClick={this.handlePrivacyNavigation}
                    >
                      {t("footer.privacy_policy")}
                    </Typography>
                    <Typography
                      className="quick-links"
                      onClick={this.navigateContactUsPage}
                    >
                      {t("footer.contact_us")}
                    </Typography>
                    <Typography
                      className="quick-links"
                      onClick={this.handleAboutUsNavigation}
                      data-test-id="aboutusRedirection"
                    >
                      {t("footer.about_us")}
                    </Typography>
                  </Box>
                </Box>
                <Box className="quick-link-text-container-1 for-responsive-desktop">
                  <Typography
                    className="quick-links"
                    data-test-id="faq-click"
                    onClick={this.handleFaqNavigation}
                  >
                    {t("footer.faqs")}
                  </Typography>
                  <Typography
                    className="quick-links"
                    onClick={this.redirectToComingSoonPages}
                  >
                    {t("footer.articles_and_news")}
                  </Typography>
                  <Typography
                    className="quick-links"
                    data-test-id="terms-click"
                    onClick={this.handleTermsNavigation}
                  >
                    {t("footer.terms_and_conditions")}
                  </Typography>
                  <Typography
                    className="quick-links"
                    data-test-id="privacy-click"
                    onClick={this.handlePrivacyNavigation}
                  >
                    {t("footer.privacy_policy")}
                  </Typography>
                  <Typography
                    className="quick-links"
                    onClick={this.navigateContactUsPage}
                  >
                    {t("footer.contact_us")}
                  </Typography>
                  <Typography
                    className="quick-links"
                    onClick={this.handleAboutUsNavigation}
                    data-test-id="aboutusRedirection"
                  >
                    {t("footer.about_us")}
                  </Typography>
                </Box>
                <Box className={`social-link-container ${addClassIfArabic(this.props.i18n)}`}>
                  <Typography className="footer-main-text footer-main-center-text">
                    {t("footer.social_media")}
                  </Typography>

                  <div className="social-icon-container">
                    {this.socialLink.map((data,index) => (
                      <img
                        className="social-icon"
                        key={index}
                        src={data.icon}
                        alt={data.label}
                        onClick={() => this.handleSocialLinkClick(data.label)}
                      />
                    ))}
                  </div>
                </Box>
                <Box className="powered-by-container">
                  <Typography className="footer-main-text footer-main-center-text">
                    {t("footer.powered_by")}
                  </Typography>
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      this.handleLinkClick("https://secoaches.co/")
                    }
                    src={frame}
                    className="frame-image"
                    alt="Footer Logo"
                  />
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={() => this.openPDFModal()}
                    src={qrcode}
                    className="qr-image"
                    alt="Footer Logo"
                  />
                </Box>
                <Box className="top-arrow-container">
                  <div
                    data-test-id="scrollButton"
                    onClick={this.props.topToScroll}
                    className="go-to-top-container"
                  >
                    <img className="go-to-top-image" src={upImg} alt="Go Up" />
                  </div>
                </Box>
              </Box>
            </Box>
          </Container>
        </FooterStyles>
        <MainContainer>
          <Box className="afterIconArrange">
            <Box className="iconArrange">
              <img src={mada} alt="logo" />
              <img src={Vector} alt="logo" />
              <img src={Layer} alt="logo" />
              <img src={apple} alt="logo" style={{ display: "none" }} />
            </Box>
            <Box className="align-items-center">
              <span className="copy-right-text">
                &copy; {t("footer.copy_right_text")}
              </span>
            </Box>
          </Box>
        </MainContainer>
      </>
    );
  }
}

export default withTranslation()(Footer);

const FooterStyles = styled(Box)(({ theme }) => ({
  backgroundColor: "#5F0AE5",
  minHeight: "445px",
  position: "relative",
  padding: "87px 0",
  boxSizing: "border-box",
  [theme.breakpoints.down("xs")]: {
    padding: "126px 0",
  },
  "& .right-half-circle": {
    position: "absolute",
    left: "0",
    top: "0",
    height: "275px",
    width: "289px",
    [theme.breakpoints.down("xs")]: {
      width: "242px",
      height: "230.28px",
    },
  },
  "& .footer-container": {
    zIndex: 1,
    position: "relative",
    "@media(min-width:1248px)": {
      maxWidth: "1248px !important",
    },
  },
  "& .footer-text-image-container": {
    display: "flex",
    gap: "157px",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      gap: "100px",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: "96px",
    },
  },
  "& .main-coachy-image": {
    width: "94px",
    height: "72.5px",
    flex: "0 0 94px",
    maxWidth: "94px",
    maxHeight: "72.5px",
  },
  "& .footer-link-container": {
    flex: "0 0 calc(100% - (94px + 157px))",
    maxWidth: "calc(100% - (94px + 157px))",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    gap: "20px",
    [theme.breakpoints.down("md")]: {
      flex: "0 0 calc(100% - (94px + 100px))",
      maxWidth: "calc(100% - (94px + 100px))",
    },
    [theme.breakpoints.down("sm")]: {
      flex: "1",
      maxWidth: "100%",
    },
    "@media(max-width:860px)": {
      flexDirection: "column",
      gap: "64px",
      alignItems: "center",
    },
  },
  "& .footer-main-text": {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "21.79px",
    color: "#FAFAFA",
    height: "11px",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  "& .footer-main-center-text":{
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  "& .footer-main-left-text":{
    textAlign:"left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  "& .quick-links": {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "16.94px",
    color: "#FAFAFA",
    cursor: "pointer",
    width: "fit-content",
    height: "10px",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  "& .quick-link-text-container": {
    display: "flex",
    justifyContent: "center",
    gap: "96px",
    [theme.breakpoints.down("xs")]: {
      gap: "30px",
      justifyContent: "space-between",
      width: "100%",
      padding: "12px",
      maxWidth: "334px",
    },
  },
  "& .quick-link-text-container-1": {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
  },
  "& .social-link-container": {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    maxWidth: "103px",
  },
  "& .social-link-container.arabic-class":{
    maxWidth: "122px",
    "& .footer-main-text":{
      height:"16px"
    }
  },
  "& .social-icon-container": {
    display: "flex",
    gap: "32px",
    flexWrap: "wrap",
  },
  "& .social-icon": {
    height: "32px",
    width: "32px",
    minWidth: "32px",
    cursor: "pointer",
  },
  "& .powered-by-container": {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
  },
  "& .top-arrow-container": {
    display: "flex",
    alignItems: "center",
  },
  "& .go-to-top-container": {
    height: "48px",
    width: "48px",
    borderRadius: "50%",
    cursor: "pointer",
  },
  "& .go-to-top-image": {
    height: "48px",
    width: "48px",
    borderRadius: "50%",
  },
  "& .frame-image": {
    height: "69px",
    width: "171px",
  },
  "& .qr-image": {
    width: "171px",
    height: "83px",
  },
  "& .for-responsive-tablet": {
    display: "none",
    marginTop: "43px",
    "@media(max-width:860px)": {
      display: "flex",
    },
  },
  "& .for-responsive-desktop": {
    marginTop: "43px",
    "@media(max-width:860px)": {
      display: "none",
    },
  },
}));

const MainContainer = styled(Container)({
  "@media(min-width:1288px)": {
    maxWidth: "1288px !important",
  },
  "& .afterIconArrange": {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
    "@media (max-width: 996px)": {
      display: "flex",
      justifyContent: "center !important",
      alignItems: "center",
      alignContent: "center",
    },
    "& img": {
      width: "53.89px",
      height: "18px",
    },
  },
  "& .iconArrange": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "24px",
    width: "423.42px",
    height: "18px",
    "@media (max-width: 996px)": {
      display: "none",
    },
    "@media (min-width: 998px) and (max-width:1280px)": {
      marginRight: "170px",
    },
  },
  "& .align-items-center": {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  "& .copy-right-text": {
    fontWeight: 600,
    fontSize: "12px",
    color: "#4307A0",
    fontFamily: "Open Sans",
    lineHeight: "16.34px",
  },
});
